// Media queries breakpoints
window.screen_xs = 480;
window.screen_xs_min = screen_xs;
window.screen_phone = screen_xs_min;
window.screen_sm = 768;
window.screen_sm_min = screen_sm;
window.screen_tablet = screen_sm_min;
window.screen_md = 992;
window.screen_md_min = screen_md;
window.screen_desktop = screen_md_min;
window.screen_lg = 1200;
window.screen_lg_min = screen_lg;
window.screen_lg_desktop = screen_lg_min;
window.screen_xs_max = (screen_sm_min - 1);
window.screen_sm_max = (screen_md_min - 1);
window.screen_md_max = (screen_lg_min - 1);

function get_vw() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

function get_vh() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

window.get_vh = get_vh;
window.get_vw = get_vw;
/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function(){
    init_product_slider(document);
});

function init_product_slider(context) {
    $('.product-slider-container', context).addClass('owl-container');
    $('.product-slider-container .product_listing', context).each(function () {
        // Add main classes
        $(this).addClass('owl-carousel');

        // Options
        $(this).owlCarousel({
            autoHeight: true,
            loop: false,
            autoplay: false,
            //autoplayTimeout: 5000,
            //autoplayHoverPause: true,
            nav: true,
            navText: [
                "<span class='icon icon-left-big'></span>",
                "<span class='icon icon-right-big'></span>",
            ],
            responsiveClass: true,
            // navContainer: '.owl-out-nav',dddddacdscvf zxxx
            slideBy: 'page',
            dots: false,
            responsive: {
                0: {
                    items: 2
                },
                750: {
                    items: 3
                },
                970: {
                    items: 4
                },
                1170: {
                    items: 5
                }
            }
        });
    });

    // resize fix
    $(window).on('load',function() {
        $('.product-slider-container .product_listing').trigger('refresh.owl.carousel');
    });
    $(document).ready(function (){
        $('.product-slider-container .product_listing').trigger('refresh.owl.carousel');
    })

    $(document).on('click', '.owl-prev, .owl-next', function(e){
        $(this).closest('.owl-carousel').trigger('refresh.owl.carousel');
    })
}

// Hide header on scroll down, show on scroll up
$(function () {
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('nav').outerHeight();

    setTimeout(function() {
        update_nav_top();
    }, 300);

    $(window).on('resize', function() {
        update_nav_top();
    });

    $(window).scroll(function(event){
        didScroll = true;
    });

    if ($(window).width() >= 768) {
        setInterval(function () {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);
    }

    function hasScrolled() {
        var st = $(this).scrollTop();

        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $('nav').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                $('nav').removeClass('nav-up').addClass('nav-down');
            }
        }

        lastScrollTop = st;
    }

    $(document).on('click', 'div.list-collapse-mobile > .title', function(e){
        if (get_vw() <= screen_sm_max) {
            var header_height = $('header').height();
            if ($('.linepromos').length) {
                var promo_height = $('.linepromos').height();
            } else {
                var promo_height = 0;
            }
            var nav_top = header_height + promo_height;

            $('#pages_mega_menu #mega_menu .list-dropdown .submenu_list').css('top', nav_top + 'px');
        }
    });

    function update_nav_top() {
        var header_height = $('header').height();
        if ($('.linepromos').length) {
            var promo_height = $('.linepromos').height();
        } else {
            var promo_height = 0;
        }
        var nav_top = header_height + promo_height;
        var submenu_height = get_vh();

        if (get_vw() >= screen_md_min) {
            $('nav').css('top', nav_top + 'px');
            content_padding = 0;
        } else {
            $('nav, #pages_mega_menu #mega_menu .list-dropdown .submenu_list, #pages_mega_menu #mega_menu .list-dropdown .row.level-3').css('top', nav_top + 'px');
            content_padding = promo_height;
        }

        $('#content_layout').css('padding-top', content_padding + 'px');
        submenu_height = submenu_height - nav_top;

        if (get_vw() <= screen_sm_max) {
            $('nav').css('height', submenu_height + 'px');
        } else {
            $('nav').css('height', '');
        }
    }
});

// Instagram web-browser fix
window.addEventListener("resize", instagramFixWidth);
instagramFixWidth();
$(function(){
    instagramFixWidth();
});

function instagramFixWidth(){
    if (window.screen.width < window.innerWidth) {
        window.width = window.screen.width;
        window.innerWidth = window.screen.width;
    }
}